import React from "react";

import Header from "../components/Header"
import Footer from '../components/Footer';
import PortfolioEntry from "../components/PortfolioEntry";
import LinksArray from "../components/Links";
import OperationsManager from "../components/OperationsManager";

import DM_Overview_0 from '../assets/compsci/DM_UI_02.png';
import DM_Overview_1 from '../assets/compsci/DM_UI_01.png';
import DA_login_0 from '../assets/compsci/DA_login_0.png';
import DA_login_3 from '../assets/compsci/DA_login_3.png';
import DA_login_4 from '../assets/compsci/DA_login_4.png';
import DA_deliveries_0 from '../assets/compsci/DA_deliveries_0.png';
import DA_deliveries_1 from '../assets/compsci/DA_deliveries_1.png';
import DA_deliveries_2 from '../assets/compsci/DA_deliveries_2.png';
import DA_signature_0 from '../assets/compsci/DA_signature_0.png';
import DA_images_2 from '../assets/compsci/DA_images_2.png';
import DA_graphics_0 from '../assets/compsci/DA_graphics_0.png'

import Fig0 from '../assets/DriverApp_1.png';
import Fig1 from '../assets/DriverApp_2.png';
import Fig2 from '../assets/DriverApp_3.png';
import Fig3 from '../assets/DriverApp_3C.png';
import Fig4 from '../assets/DriverApp_4.png';
import Fig5 from '../assets/DriverApp_5.png';
import Fig6 from '../assets/DriverApp_6.png';

import styles from '../styles/Portfolio.module.css';

const CompSci_0 = () => {
    return (
        <>
            <OperationsManager />;
            <span className="half_buffer"></span>
            <Footer page="/compsci/0" dest="land cover segmentation"/>
        </>
    );
};

export default CompSci_0;