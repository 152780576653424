// import React, components and state...
import React, { useState } from "react";
import ToggleArray from "./ToggleArray";

// UX/UI workflow diagram...
import UI_Delivery from '../assets/compsci/OperationsManager_UI_03.png';
import DM_validation from '../assets/compsci/DM_validation_0.png';

// delivery manifests before/after update...
import DM_deliveries_0 from '../assets/compsci/DM_deliveries_0.png';
import DM_deliveries_1 from '../assets/compsci/DM_deliveries_4.png';

// delivery form empty/signature/image...
import DM_deliveries_form_empty from '../assets/compsci/DM_deliveries_form_0.png';
import DM_deliveries_signature from '../assets/compsci/DM_deliveries_signature.png';
import DM_deliveries_form_sign from '../assets/compsci/DM_deliveries_form_1.png';
import DM_deliveries_form_img from '../assets/compsci/DM_deliveries_form_2.png';

// css styling...
import '../styles/Portfolio.css';

const DeliveryManager = () => {
    const images1 = {
        content: [DM_validation, DM_deliveries_0, DM_deliveries_form_empty],
        captions: ["delivery validation", "delivery manifest", "delivery form"]
    };
    const images2 = {
        content: [DM_deliveries_signature, DM_deliveries_form_sign, DM_deliveries_form_img, DM_deliveries_1],
        captions: ["signature collection", "signature only form", "signature and image form", "updated delivery manifest"]
    };

    return (
        <>
            <div id="dm1" className="module">
                <div className="modBlock">
                    <h2>delivery manager</h2>
                    <h5>delivery service</h5>
                    <p>users with access rights to the delivery manager service query deliveries by delivery date and vehicle powerunit to access their respective deliveries, any non-valid date/vehicle permutation is are rejected.</p>
                    <p>drivers can make changes to both delivered and undelivered deliveries, seamlessly add signatures and images, and record delivery alphanumeric data...ensuring our client receives up to date delivery information.</p>
                </div>
            </div>

            <div className="scrollBlock">
                <img className="portfolioimg noshadow" src={UI_Delivery} alt="signature/image upload process" loading="lazy"/>
            </div>

            <div id="dm1" className="module">
                <div className="modBlock">
                    <h5>delivery manifest</h5>
                    <p>after successful user validation, drivers are directed to the delivery manager microservice where they are immediately prompted to enter a valid delivery date and powerunit (truck id) combination.</p>
                    <p>once verified to exist in the current companies database, the driver gains access to the provided delivery manifest. with each delivery divided into uncompleted and completed drop-offs, and further ordered by stop number to help drivers keep organized.</p>
                </div>
            </div>

            <ToggleArray images={images1} defaultIndex={0}/>

            <div id="dm1" className="module">
                <div className="modBlock">
                    <h5>signature + image management</h5>
                    <p>the expanded delivery information page empowers drivers to input delivery details through text and number fields, while leveraging custom-built widgets for advanced functionalities like signature collection and image capture.</p>
                    <p>the upload process involves saving an image to a pre-defined path, with the text path being the only asset stored in database. subsequent accesses request the image at the stored file path, returning the image for rendering.</p>
                </div>
            </div>

            <ToggleArray images={images2} defaultIndex={0}/>

            {/*<div className="scrollBlock">
                <img className="portfolioimg noshadow" src={DA_images_2} alt="signature/image upload process" loading="lazy"/>
            </div>*/}
        </>
    );
};

export default DeliveryManager;