// import react and state...
import React, { useState } from "react";

// components...
import Header from "./Header"
import Footer from './Footer';
import LinksArray from "./Links";

// micro services...
import LoginPortal from "./LoginPortal";
import DeliveryManager from "./DeliveryManager";
import AdminPortal from "./AdminPortal";
import WarehouseScanner from "./WarehouseScanner";
/*const LoginPortal = React.lazy(() => import("./LoginPortal"));
const DeliveryManager = React.lazy(() => import("./DeliveryManager"));
const AdminPortal = React.lazy(() => import("./AdminPortal"));
const WarehouseScanner = React.lazy(() => import("./WarehouseScanner"));*/

// css styling...
import '../styles/Portfolio.css';

// project page logic; serves suite of services...
const OperationsManager = () => {
    // state: which service/module tab to render...
    const [activeModule,setActiveModule] = useState("login");

    // handle service selection clicking...
    const toggleModule = (e) => {
        // collect target module and array of all modules...
        const target = e.target.closest(".module-selector-title");
        const modules = target.parentElement.children;

        // iterate module titles and style accordingly...
        for (var i = 0; i < modules.length; i++) {
            if (modules[i].id === target.id) { // set active...
                setActiveModule(target.children[0].innerHTML);
                modules[i].classList.remove("module-selector-inactive");
                modules[i].classList.add("module-selector-active");
            } else { // set inactive...
                modules[i].classList.remove("module-selector-active");
                modules[i].classList.add("module-selector-inactive");
            }
        };
    };

    return (
        <>
            <Header/>
            <span className="buffer"></span>
            <LinksArray page="compsci" />
            <div id="dm0" className="module">
                <div className="modBlock">
                    <h2>operations manager</h2>
                    <h5>trucking logistics software</h5>
                    <p>this robust suite of mobile-friendly web applications was developed for a trucking logistics company to streamline delivery tracking and driver log management, with a prominent client base including home depot.</p>
                    <p>featuring a responsive react-based frontend (javaScript, css, html) that delivers an intuitive user experience, paired with a scalable .net backend (c#) for secure and efficient handling of database transactions.</p>
                    <p>hosted on a linux/apache web server, this solution provides drivers with the ability to view, edit, and update their delivery records directly from their personal mobile devices.</p>
                </div>
            </div>

            <div className="module-selector-cont" onClick={toggleModule}>
                <div className="module-selector-header">
                    <div id="module-selector-lp" className="module-selector-title start module-selector-active">
                        <p className="module-selector-text">login{/*portal*/}</p>
                    </div>
                    <div id="module-selector-ap" className="module-selector-title module-selector-inactive">
                        <p className="module-selector-text">admin{/*portal*/}</p>
                    </div>
                    <div id="module-selector-dm" className="module-selector-title module-selector-inactive">
                        <p className="module-selector-text">delivery{/*manager*/}</p>
                    </div>
                    <div id="module-selector-ws" className="module-selector-title end module-selector-inactive">
                        <p className="module-selector-text">warehouse{/*scanner*/}</p>
                    </div>
                </div>
            </div>

            {activeModule === "login" && <LoginPortal />}
            {activeModule === "admin" && <AdminPortal />}
            {activeModule === "delivery" && <DeliveryManager />}
            {activeModule === "warehouse" && <WarehouseScanner />}
        </>
    );
};

export default OperationsManager;