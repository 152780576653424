import '../styles/Portfolio.css';
import { useState } from 'react';

const ToggleArray = ({images, defaultIndex}) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    const toggleImages = (e) => {
        if (!isTouchDevice() && e.type === "click") return;

        const clickedIndex = [...e.currentTarget.children].indexOf(e.target.closest(".toggle-elmt"));
        if (clickedIndex !== -1) {
            setActiveIndex(clickedIndex);
        }      
    };

    console.log(images.content.length);
    // handle 3 images...
    if (images.content.length <= 3) {
        return (
            <div className="toggle-cont" onClick={toggleImages}>
                {images.content.map((src,index) => (
                    <div 
                        key={index} 
                        className={`toggle-elmt ${index === activeIndex ? "toggle-active" : "toggle-inactive"}`}
                        onMouseEnter={() => setActiveIndex(index)}
                        //onMouseLeave={() => setActiveIndex(0)}
                    >
                        <img className="toggle-image" src={src} alt={images.captions[index]} loading="lazy"/>
                    </div>
                ))}
            </div>
        );
    // handle 4 images...
    } else if (images.content.length <= 4) {
        return (
            <div className="toggle-cont toggle-cont-4" onClick={toggleImages}>
                {images.content.map((src,index) => (
                    <div 
                        key={index} 
                        className={`toggle-elmt toggle-elmt-4 ${index === activeIndex ? "toggle-active toggle-active-4" : "toggle-inactive toggle-inactive-4"}`}
                        onMouseEnter={() => setActiveIndex(index)}
                        //onMouseLeave={() => setActiveIndex(0)}
                    >
                        <img className="toggle-image toggle-image-4" src={src} alt={images.captions[index]} loading="lazy"/>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <h3>Error!</h3>
        );
    }
    
};

export default ToggleArray;