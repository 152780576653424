import React, { useState } from "react";

import ToggleArray from "./ToggleArray";

import DA_login_0 from '../assets/compsci/DA_login_0.png';
import DA_login_1 from '../assets/compsci/DA_login_1.png';
import DA_login_2 from '../assets/compsci/DA_login_2.png'
import DA_login_3 from '../assets/compsci/DA_login_3.png';
import DA_login_4 from '../assets/compsci/DA_login_4.png';
import DA_login_5 from '../assets/compsci/DA_login_5.png';

import UI_Login from '../assets/compsci/OperationsManager_UI_01.png';

import '../styles/Portfolio.css';

const LoginPortal = () => {
    const images = {
        content: [DA_login_0, DA_login_1, DA_login_2],
        captions: ["login credentials","new user sign in","new user form"]
    };
    const images1 = {
        content: [DA_login_3, DA_login_4,DA_login_5],
        captions: ["error handling", "company selection","module selection"]
    };

    return (
        <>
            <div id="lp0" className="module">
                <div className="modBlock">
                    <h2>login portal</h2>
                    <h5>user authentication + session management</h5>
                    <p>the login portal serves as a secure entry point for both drivers and administrative users, providing tailored access to delivery and management tools.</p>
                    <p>utilizing username and password credentials, the portal ensures that only authorized users gain access. the token-based authorization process safeguards subsequent api requests and sensitive resources for the duration of the current session.</p>
                    <p>client-side input validation protects api endpoints from errant input data by blocking requests from sending while providing dynamic visual cues to the user.</p>
                </div>
            </div>

            <div className="scrollBlock">
                <img className="portfolioimg noshadow" src={UI_Login} alt="signature/image upload process" loading="lazy"/>
            </div>

            <div id="lp0" className="module">
                <div className="modBlock">
                    <h5>user validation</h5>
                    <p>the login portal serves as a secure entry point for both drivers and administrative users, providing tailored access to delivery and management tools.</p>
                    <p>utilizing username and password credentials, the portal ensures that only authorized users gain access. the token-based authorization process safeguards subsequent api requests and sensitive resources for the duration of the current session.</p>
                    <p>client-side input validation protects api endpoints from errant input data by blocking requests from sending while providing dynamic visual cues to the user.</p>
                </div>
            </div>

            <ToggleArray images={images} defaultIndex={1}/>

            <div id="lp1" className="module">
                <div className="modBlock">
                    <h5>intuitive login process</h5>
                    <p>client-side input validation protects api endpoints from errant input data by blocking requests from sending while providing dynamic visual cues to the user.</p>
                    <p>successful authorization brings users are presented selections for the companies and services available for their use, choices which are cached in secure cookies for use by the next service...allowing for a single login protocol for all services.</p>
                    <p>company, user and token data is stored in cookies set to expire after 15 minutes, while refresh tokens persist for 24 hours. session data is retained while the browser tab remains open, but clears when leaving the site.</p>
                </div>
            </div>

            <ToggleArray images={images1} defaultIndex={0}/>
        </>
    );
};

export default LoginPortal;

/*
<PortfolioEntry id={styles.dm6} className={styles.dm} params={DM6_params} scroll={true}/>
<PortfolioEntry id={styles.dm7} className={styles.dm} params={DM7_params}/>
<PortfolioEntry id={styles.dm8} className={styles.dm} params={DM8_params}/>
<PortfolioEntry id={styles.dm9} className={styles.dm} params={DM9_params}/>
*/