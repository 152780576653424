import React, { useState } from "react";

import ToggleArray from "./ToggleArray";

import DA_login_0 from '../assets/compsci/DA_login_0.png';
import DA_login_3 from '../assets/compsci/DA_login_3.png';
import DA_login_4 from '../assets/compsci/DA_login_4.png';

import DA_admin_0 from '../assets/compsci/DA_admin_0.png';
import DA_admin_1 from '../assets/compsci/DA_admin_1.png';
import DA_admin_2 from '../assets/compsci/DA_admin_2.png';
import DA_admin_3 from '../assets/compsci/DA_admin_3.png';
import DA_admin_4 from '../assets/compsci/DA_admin_4.png';
import DA_admin_5 from '../assets/compsci/DA_admin_5.png';
import DA_admin_6 from '../assets/compsci/DA_admin_6.png';

import DM_Overview_1 from '../assets/compsci/DM_UI_01.png';
import UI_Admin from '../assets/compsci/OperationsManager_UI_02.png';

import '../styles/Portfolio.css';

const AdminPortal = () => {
    const images1 = {
        content: [DA_admin_0,DA_admin_1,DA_admin_6],
        captions: ["admin menu","new user creation","user update success"]
    };

    const images2 = {
        content: [DA_admin_2,DA_admin_5,DA_admin_4],
        captions: ["user search","user update","company renaming"]
    };

    return (
        <>
            <div id="ap0" className="module">
                <div className="modBlock">
                    <h2>admin portal</h2>
                    <h5>staff management + permissions</h5>
                    <p>users with administrative rights have the option to make direct changes to the overarching user database. allowing for the ability to add, edit and remove users; allowing setting the list of companies and services available for the chosen user.</p>
                    <p>custom visual prompts have been incorporated on all major actions, seen throughout the entire suite of microservices, to ensure the end user is informed on their progress.</p>
                </div>
            </div>

            <div className="scrollBlock">
                <img className="portfolioimg noshadow" src={UI_Admin} alt="signature/image upload process" loading="lazy"/>
            </div>

            <div id="ap1" className="module">
                <div className="modBlock">
                    <h5>adding new users</h5>
                    <p>administrators are able to work through an intuitive graphic user interface (gui) for adding new users, leveraging interactive text and check boxes in lieu of strictly manual data entry.</p>
                    <p>on the first admin selection, the application queries our internal database and returns the companies and microservices that the respective user has access to. the options configured at this time will limit the options presented to the respective user at login time.</p>
                </div>
            </div>
            <ToggleArray images={images1} defaultIndex={0}/>

            <div id="ap2" className="module">
                <div className="modBlock">
                    <h5>editing users/companies</h5>
                    <p>administrative staff also have the ability to edit/remove employee records at any point between user generation and deletion. users can change access rights to additional company databases and microservices at the touch of a button.</p>
                    <p>while it is possible to manipulate a user's password at any time, for privacy concerns it is preferred to direct first time users to set their initial password themselves using the gui based approach on their first login.</p>
                    <p>to retain control over sensitive records, we dont allow users to manipulate database names, however they are allowed to manipulate the verbose name their companies are referred to by throughout the microservices.</p>
                </div>
            </div>
            <ToggleArray images={images2} defaultIndex={0}/>
        </>
    );
};

export default AdminPortal;

/*
<PortfolioEntry id={styles.dm6} className={styles.dm} params={DM6_params} scroll={true}/>
<PortfolioEntry id={styles.dm7} className={styles.dm} params={DM7_params}/>
<PortfolioEntry id={styles.dm8} className={styles.dm} params={DM8_params}/>
<PortfolioEntry id={styles.dm9} className={styles.dm} params={DM9_params}/>
*/